<template>
  <div>
    <div class="d-flex justify-start align-center mb-8">
      <div>
        <v-avatar
          color="primary"
          class="v-avatar-light-bg primary--text mr-2"
          size="35"
        >
          <v-icon
            dense
            color="primary"
          >
            {{ icons.mdiTownHall }}
          </v-icon>
        </v-avatar>
      </div>
      <div>
        <div v-if="isLoadingInstance">
          <v-progress-circular
            color="primary"
            :size="30"
            indeterminate
          ></v-progress-circular>
        </div>
        <div v-else>
          <span class="d-block text-caption text-capitalize black--text">{{ instance.company_types_uuid.type }}</span>
          <span
            class="d-block text-subtitle-1 font-weight-bold black--text"
            style="line-height: 1.5rem !important"
          >
            {{ instance.name }}
          </span>
        </div>
      </div>
    </div>

    <div id="add-class">
      <router-link
        to="/classes"
        class="text-decoration-none text-subtitle-2 font-weight-bold"
      >
        <v-icon
          color="primary"
          dense
          class="mr-2"
        >
          {{ icons.mdiArrowLeft }}
        </v-icon>
        Kembali
      </router-link>
      <h3 class="my-8">
        Tambah Kelas
      </h3>
      <v-card class="pa-4">
        <v-card-text>
          <div class="mb-8">
            <h3>
              Informasi Kelas
            </h3>
            <span>
              Informasi untuk kelas
            </span>
          </div>
          <v-row class="my-3">
            <v-col
              cols="12"
              class="py-0"
            >
              <div>
                <v-text-field
                  v-model="grade.name"
                  outlined
                  dense
                  label="Nama Kelas"
                ></v-text-field>
              </div>
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <div>
                <v-text-field
                  v-model="grade.major"
                  outlined
                  dense
                  label="Jurusan"
                ></v-text-field>
              </div>
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <div>
                <v-text-field
                  v-model="grade.class_level"
                  outlined
                  dense
                  label="Tingkatan Kelas"
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-card-actions class="justify-center mt-5">
            <v-btn
              color="primary"
              large
              :loading="isLoadingButton"
              :disabled="!formValid"
              @click="add()"
            >
              Simpan
            </v-btn>
            <v-btn
              color="primary"
              outlined
              large
              @click="returnBack"
            >
              Batal
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiTownHall, mdiArrowLeft } from '@mdi/js'
// import InfiniteLoading from 'vue-infinite-loading'

export default {
  name: 'ClassesForm',
  data() {
    return {
      icons: {
        mdiTownHall,
        mdiArrowLeft,
      },
      isLoadingCard: true,
      search: '',
      isLoadingData: true,
      instance: {},
      isLoadingInstance: false,
      isLoadingButton: false,
      serviceInstance: 'instance',
      service: 'classes',
      instanceUuid: JSON.parse(localStorage.getItem('user')).user.company_uuid,
      grade: {
        name: '',
        class_level: '',
        major: '',
        company_uuid: JSON.parse(localStorage.getItem('user')).user.company_uuid,
      },
      formValid: false,
    }
  },
  watch: {
    grade: {
      handler() {
        const valid = []
        const requiredField = ['name', 'class_level', 'major']
        Object.entries(this.grade).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
  },
  created() {
    this.getDetailInstance()
  },
  methods: {
    async getDetailInstance() {
      this.isLoadingInstance = true

      await this.$services.ApiServices.get(this.serviceInstance, this.instanceUuid).then(
        ({ data }) => {
          this.instance = data.data
          this.isLoadingInstance = false
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingInstance = false
    },
    async add() {
      this.isLoadingButton = true
      await this.$services.ApiServices.add(this.service, this.grade).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.modalDialog = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      this.resetForm()
      this.returnBack()
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
    returnBack() {
      return this.$router.back()
    },
    resetForm() {
      this.grade = {}
    },
  },
}
</script>
